
import React, { Component } from 'react';
import './jumbotron.css';
import Viewer from '../Viewer/Viewer';
import classnames from 'classnames';
import scrollTo from 'scroll-to';
import Properties from '../Properties';
import { Routes, Route, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

import {
  viewerResize,
  viewerExplode,
  toggleExplosion,
  toggleRotation,
  stopMotion,
  modelRestoreState,
  toggleMeasureTool

} from '../Viewer/Viewer-helpers';

class Jumbotron extends Component {

  constructor() {
    super();

    this.state = {
      fullscreen: false,
      explode: false,
      expMotion: false,
      rotMotion: false,
      resetState: false,
      properties: false,
      value: 0
    }

    this.onExplode = this.onExplode.bind(this);
    this.onExplodeAnimation = this.onExplodeAnimation.bind(this);
    this.onRotateAnimation = this.onRotateAnimation.bind(this);
    this.onResetState = this.onResetState.bind(this);
    this.onOrientationChange = this.onOrientationChange.bind(this);
    this.onPropertiesDisplay = this.onPropertiesDisplay.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.onSelectionChange = this.onSelectionChange.bind(this);
    this.onToggleMeasurement = this.onToggleMeasurement.bind(this);
  }

  componentDidMount() {
    window.addEventListener('orientationchange', this.onOrientationChange);
    this.setState({ fullscreen: !this.state.fullscreen }, () => {

      if (this.state.fullscreen) {
        scrollTo(0, 0, {
          ease: 'inQuad',
          duration: 300
        });
        document.body.classList.add('fullscreen')
      } else {
        document.body.classList.remove('fullscreen');
        this.onResetState();
        if (this.state.explode){
          document.body.classList.remove('explode');
        }

        this.setState({
          fullscreen: false,
          explode: false,
          expMotion: false,
          rotMotion: false,
          resetState: false,
          properties: false,
          value: 0
        })
      }

    
    });
  }

  componentWillUnmount() {
    window.removeEventListener('orientationchange', this.onOrientationChange);
  }

  handleValueChange(event) {
    this.setState({
      value: event.target.value
    });
    viewerExplode(this.state.value/100);

  }

  onPropertiesDisplay(){
    this.setState({ properties: !this.state.properties }, () => {
      if (this.state.properties) {
        document.body.classList.add('properties-show');
      } else {
        document.body.classList.remove('properties-show');
      }
    });
  }

  onExplode(){
    this.setState({ explode: !this.state.explode }, () => {
      if (this.state.explode) {
        document.body.classList.add('explode')
      } else {
        document.body.classList.remove('explode');
      }
    });
  }

  onOrientationChange() {
    setTimeout(() => viewerResize(), 300);
  }

  onExplodeAnimation() {
    this.setState({ expMotion: !this.state.expMotion}, () => {
      if (this.state.expMotion) {
        document.body.classList.add('explode-motion')
        toggleExplosion();
      } else {
        document.body.classList.remove('explode-motion');
        toggleExplosion();
      }
    });
  }

  onRotateAnimation() {
    this.setState({ rotMotion: !this.state.rotMotion }, () => {
      if (this.state.rotMotion) {
        document.body.classList.add('rotate-motion')
        toggleRotation();
      } else {
        document.body.classList.remove('rotate-motion');
        toggleRotation();
      }
    });
  }

  onResetState() {
    document.body.classList.remove('explode', 'explode-motion', 'rotate-motion');
    this.setState({explode: false, expMotion: false, rotMotion: false});
    stopMotion();
    modelRestoreState();
  }
  onToggleMeasurement(){
    

    return toggleMeasureTool()
  }

   

  onSelectionChange() {
    if (!this.state.properties) {
      return;
    }

    // this.setState({
    //
    // })
  }

  handlePropertiesClose = () => {
    this.setState({ properties: false })
  }

  render() {


    const buttonClass = classnames({
      'fa': true,
      'fa-expand': !this.state.fullscreen,
      'fa-compress': this.state.fullscreen,
    });

    const propertiesClass = classnames({
      'fa': true,
      'fa-list': this.state.fullscreen,
    });

    const explodeClass = classnames({
      'fa': true,
      'fa-cubes': this.state.fullscreen,
    });

    const explodeMotionClass = classnames({
      'fa': true,
      'fa-bomb': this.state.fullscreen,
    });

    const rotateMotionClass = classnames({
      'fa': true,
      'fa-repeat': this.state.fullscreen,
    });

    const resetClass = classnames({
      'fa': true,
      'fa-refresh': this.state.fullscreen,
    });

    const propertiesBtnClass = classnames({
      'properties-btn': true,
      'btn--active': this.state.properties,
      'btn--deactive': !this.state.properties
    })

    const explodeBtnClass = classnames({
      'explode-btn': true,
      'btn--active': this.state.explode,
      'btn--deactive': !this.state.explode
    })

    const explodeMotionBtnClass = classnames({
      'explode-motion-btn': true,
      'expbtn--active': this.state.expMotion,
      'expbtn--deactive': !this.state.expMotion
    })

    const rotateMotionBtnClass = classnames({
      'rotate-motion-btn': true,
      'rotbtn--active': this.state.rotMotion,
      'rotbtn--deactive': !this.state.rotMotion
    })

    const resetBtnClass = classnames({
      'reset-btn': true,
      'resetbtn--deactive': !this.state.rotMotion
    })

    return (
      <div className="plaved-jumbotron">
        <Viewer urn={this.props.match?.params.urn}/>
        <div className="plaved-logo">
            <img src="images/tdv-plaved.png" alt="Plaved Logo" />
        </div>
        <div className="container-adsk">
         
         <div className='botonera-izda'>
      
      
          <button  onClick={this.onExplodeAnimation} >
          <FontAwesomeIcon icon={icon({name: "maximize", style: 'solid'})} />             </button>

          <button  onClick={this.onPropertiesDisplay} >
          <FontAwesomeIcon icon={icon({name: "envelope-open", style: 'solid'})} />   
          </button>
          <button  onClick={this.onResetState} >
          <FontAwesomeIcon icon={icon({name: "rotate", style: 'solid'})} />              
          </button>
          <button  onClick={() => this.onToggleMeasurement()} >
          <FontAwesomeIcon icon={icon({name: "ruler-combined", style: 'solid'})} />                    </button>
         </div>

          {
            this.state.properties
             ? <Properties onClose={this.handlePropertiesClose} />
             : null
          }

          <input type="range"
              className="range-style"
              min="0"
              max="100"
              value={this.state.value}
              onChange={this.handleValueChange}
          />
        </div>
      </div>
    );
  }
}

export default Jumbotron;
