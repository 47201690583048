
import React, { Component } from 'react';
import Helpers from './Viewer-helpers';

class Viewer extends Component {

    constructor(props){
        super(props);

        this.state = {
            urn: "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6cGxhdmVkLXZpZXdlci9yYWNiYXNpY3NhbXBsZXByb2plY3QucnZ0"
        }
    }

    componentDidMount() {
        this.setState({urn: this.props.urn})
        
        // RC Car Model
        var documentId = this.props.urn ? 'urn:'+this.props.urn : 'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6cGxhdmVkLXZpZXdlci9yYWNiYXNpY3NhbXBsZXByb2plY3QucnZ0' ;
        Helpers.launchViewer('viewerDiv', documentId , '0002');        
    }

	render() {
        return (
            <div className='forge-viewer' id="viewerDiv" />
        );
	}
}

export default Viewer;
