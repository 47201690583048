
import React, { Component } from 'react';
import './footer.css';

class Footer extends Component {
   render() {
    return (
      <div className="plaved-footer">
    
      </div>
    );
  }
}

export default Footer;
