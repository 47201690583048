
import axios from 'axios';

function getaccesstoken() {
	return axios.get('https://estrella.plaved.tech/plaved/obras/visor/auth', {
		headers: {
			'plaved-app-auth': "4f12aa03029bcd5141053d8b8ca296d6"
		}
	})
  	.then(function (response) {
  		return response.data;
  	})
	.catch(function (error) {
   	 	console.log(error);
  	});
}

const Client = { getaccesstoken };
export default Client;