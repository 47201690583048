
/* global Autodesk, THREE */
import { debounce, uniq } from 'lodash';
import { actions } from '../../actions';
import { store } from '../../store';

import Client from '../Client';
var viewer;
var getToken = { accessToken: Client.getaccesstoken()};
var explodeScale = 0;
var startExplosion = null;
var explosionReq;
var isExploding = false;
var outwardExplosion = true;
var startRotation = null;
var rotationReq;
var isRotating = false;
var tileId = '';
export var properties = {};



function launchViewer(div, urn, id) {
  tileId = id;
  getToken.accessToken.then((token) => {
    var options = {
      'document': urn,
      'env': 'AutodeskProduction',
      'accessToken': token.access_token
      // 'refreshToken': getForgeToken
    };

    var viewerElement = document.getElementById(div);
    viewer = new Autodesk.Viewing.Viewer3D(viewerElement, {});

    Autodesk.Viewing.Initializer(
      options,
      function () {
        viewer.initialize();
        viewer.prefs.tag('ignore-producer')
        loadDocument(options.document);
      }
    );
  })
}

function loadDocument(documentId){
  Autodesk.Viewing.Document.load(
    documentId,
    function (doc) { // onLoadCallback
      var defaultModel = doc.getRoot().getDefaultGeometry();

        viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, onGeometryLoaded);
        viewer.addEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, debounce(() => {
          console.log('selection change')
          getModelProperties()
            .then((modelProperties) =>
              store.dispatch(actions.getViewerProperties(modelProperties))
            )
            .catch(() =>
              store.dispatch(actions.getViewerProperties([]))
            )
        }), 200);

        viewer.loadDocumentNode(doc, defaultModel);
      
    },
    function (errorMsg) { // onErrorCallback
      console.log(errorMsg);
    }
  )
}


//////////////////////////////////////////////////////////////////////////
// Model Geometry loaded callback
//
//////////////////////////////////////////////////////////////////////////
function onGeometryLoaded(event) {
        var viewer = event.target;
        viewer.removeEventListener(
                Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
                onGeometryLoaded);
        viewer.fitToView();
        viewer.setQualityLevel(false,false); // Getting rid of Ambientshadows to false to avoid blackscreen problem in Viewer.
    }


function getModelProperties() {
  return new Promise((resolve, reject) => {
    const dbId = viewer.getSelection()[0];

    if (viewer.getSelectionCount() !== 1) {
      return reject('Invalid selection count');
    }

    return new Promise((resolve) => {
      viewer.model.getProperties(dbId, (item) => {
        const items = item.properties.filter((property) => !property.hidden);
        resolve(items);
      });
    })
    .then((list) => {

      // Normalize displayCategory property in case it's falsy
      list = list.map(item => ({
        ...item,
        displayCategory: item.displayCategory || 'Miscellaneous'
      }));

      // Unique list of categories
      const categories = uniq(list.map(item => item.displayCategory));

      // Model data to be consumed
      // Ex: [ {category: 'Miscellaneous', data: []} ]
      const properties = categories.map(category => (
        {
          category,
          data: list.filter(item => item.displayCategory === category)
        }
      ));

      resolve(properties);
    });
  });
}

export function viewerResize() {
  viewer.resize();
}

export function viewerExplode(num){
  viewer.explode(num);
}

export function modelRestoreState(){
  
  




viewer.restoreState("", false, false);
}

export function toggleMeasureTool(){
return viewer.loadExtension("Autodesk.Measure").then(extension => {

  console.log(extension.activeStatus)
function deactivateMeasurement(){
  
    
    extension.deactivate()
}
  
  extension.activeStatus ?  deactivateMeasurement() :extension.activate()})

  
}

 /**
     * toggle explosion motion
     * @param  boolean cancelMotion - true if cancel motion is requested
     */
export function toggleExplosion(cancelMotion) {
      if (cancelMotion || isExploding) {
        cancelAnimationFrame(explosionReq);
        isExploding = false;
        if (cancelMotion) {
          explodeScale = 0;
          viewer.explode(explodeScale);
        }
      } else {
        explodeMotion();
        isExploding = true;
      }
    }

/**
  * Recursive function for calling requestAnimationFrame for explode motion
*/

export function explodeMotion(timestamp) {
      if (!startExplosion) {
        startExplosion = timestamp;
      }
      var progress = timestamp - startExplosion;
      startExplosion = timestamp;
      var explodeStep = 0.0002 * (progress || 0);
      // explode outward and inward
      if (outwardExplosion) {
        explodeScale += explodeStep;
      } else {
        explodeScale -= explodeStep;
      }
      if (explodeScale > 1) {
        outwardExplosion = false;
        explodeScale = 1; // this solves when user go to another browser tab
      } else if (explodeScale < 0) {
        outwardExplosion = true;
        explodeScale = 0; // this solves when user go to another browser tab
      }
      viewer.explode(explodeScale);
      explosionReq = window.requestAnimationFrame(explodeMotion);
    };

 /**
     * recursive function for rotation motion each time page refreshes
     */
export function rotateMotion(timestamp) {
      if (!startRotation) {
        startRotation = timestamp;
      }
      var progress = timestamp - startRotation;
      startRotation = timestamp;
      var rotateStep = 0.0005 * (progress || 0);
      // get the up axis
      var worldUp = viewer.navigation.getWorldUpVector();
      // get the current position
      var pos = viewer.navigation.getPosition();
      // copy that position
      var position = new THREE.Vector3(pos.x, pos.y, pos.z);
      // set the rotate axis
      var rAxis = new THREE.Vector3(worldUp.x, worldUp.y, worldUp.z);
      var matrix = new THREE.Matrix4().makeRotationAxis(rAxis, rotateStep);
      //apply the new position
      position.applyMatrix4(matrix);
      viewer.navigation.setPosition(position);
      rotationReq = window.requestAnimationFrame(rotateMotion);
    }


     /**
     * Toggle the rotation movement
     * @param  boolean cancelMotion true if motion is to be cancelled
     */
export function toggleRotation(cancelMotion) {
      if (cancelMotion || isRotating) {
        cancelAnimationFrame(rotationReq);
        isRotating = false;
      } else {
        rotateMotion();
        isRotating = true;
      }
    }

 export function stopMotion() {
      toggleExplosion(true);
      toggleRotation(true);
    }


const Helpers = {
  launchViewer,
  loadDocument
};

export default Helpers;
