
import React, { Component } from 'react';
import Jumbotron from './Jumbotron';
import Footer from './Footer';
import Gallery from './Gallery';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

import { Routes, Route, Link, useParams } from "react-router-dom";
class App extends Component {
  
  render() {
    const Wrapper = (props) => {
      const params = useParams();
      return <Jumbotron  {...{...props, match: {params}} } />
    }
    return (
      <div>
          <Routes>

          <Route path="/"  element={<Wrapper />} />
        <Route path="/:urn"  element={<Wrapper />} />
      </Routes>

     
      </div>
    );
  }
}

export default App;
